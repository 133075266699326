
import { defineComponent } from 'vue'
import WatFocus from '~/components/WatFocus/WatFocus.vue'
import authStore from '@/store/auth.store'
import {
    IonPage,
    IonContent,
} from '@ionic/vue'
import store from '@/store'
export default defineComponent({
    name: 'WatFocusExecution',
    props:{
        isPublic:{
            type: Boolean,
            default: false
        }
    },
    components:{
        IonPage,
        IonContent,
        WatFocus
    },
    data(){
        return{
            nickname: "",
            visitorToken:""
        }
    },
    methods:{
        async fetchPublicTester(){
            await store.dispatch("FETCH_FOCUS_TESTER",{isPublic: this.isPublic, visitorToken: this.$route.params.auth,id: this.$route.params.id, slotHash: this.$route.params.slotHash})
            .then((data) => {
                this.nickname = data
            })
        }
    },
    async created(){
        if(this.isPublic){
            this.visitorToken = Array.isArray(this.$route.params.auth) ? this.$route.params.auth[0] : this.$route.params.auth as string;
            await this.fetchPublicTester()
        }
        else{
            this.nickname = authStore.state.userData.nickname
        }
    }
})
